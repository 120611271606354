<template>
  <div class="ms-otp-registration">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-form-title">{{ $t('auth.acceptAgreement.title') }}</div>
    <div class="ms-otp-caption" v-html="$t('auth.acceptAgreement.subtitle')" />
    <div class="ms-otp-registration-title-wrapper">
    </div>

    <div class="ms-otp-form-content-wrapper">
      <div class="ms-otp-register-agree-text" v-html="privacyPolicy"/>
      <span v-bind:class="[privacyFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
          <input type="checkbox" class="ms-otp-checkbox" id="privacy" name="privacy" v-model="privacy"
                 @change="privacyFieldErrorMessage = privacy">
          <label class="ms-otp-check-label" for="privacy"
                 v-html="$t('auth.patientRegistration.dataPrivacy.label', [localizeAgreementUrl('/content/legal')])"></label>
        </span>
      <span v-bind:class="[agbFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
          <input type="checkbox" class="ms-otp-checkbox" id="agb" name="agb" v-model="agb"
                 @change="agbFieldErrorMessage = agb">
          <label class="ms-otp-check-label" for="agb"
                 v-html="$t('auth.patientRegistration.agb.label', [localizeAgreementUrl('/content/userAgreement')])"></label>
        </span>
      <div class="ms-otp-btn-wrap">
        <button @click="declineUpdateAgreement" class="ms-otp-btn ms-otp-btn-lg ms-otp-btn-outline">{{ $t('auth.acceptAgreement.button.decline') }}</button>
        <button @click="submitUpdateAgreement" class="ms-otp-btn ms-otp-btn-lg">{{ $t('auth.acceptAgreement.button.accept') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mixinLoader} from "@/mixins/mixin-loader";
import IconEye from "@/components/icons/IconEye.vue";
import IconEyeSlashed from "@/components/icons/IconEyeSlashed.vue";
import UrlManager from "@/util/UrlManager";
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessService} from "@/service/SigninProcessService";
import {SigninProcessStep} from "@/constants/SigninProcessStep";

export default {
  name: 'UpdateAgreement',
  mixins: [mixinLoader],
  components: {
    IconEye,
    IconEyeSlashed
  },
  metaInfo () {
    return {
      title: this.$t('auth.acceptAgreement.title')
    }
  },
  data () {
    return {
      errors: [],
      email: '',
      agb: false,
      privacy: false,
      privacyPolicy: ''
    }
  },
  watch: {
    currentLocale (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showSpinner()
        this.fetchPrivacyPolicy()
          .then(() => this.hideSpinner())
      }
    }
  },
  computed: {
    solutionHost () {
      return process.env.VUE_APP_SOLUTION_HOST
    },
    privacyFieldErrorMessage: {
      get () {
        return this.getFieldError('privacy')
      },
      set (val) {
        if (!val) return
        const locationError = this.getLocationError('privacy')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    agbFieldErrorMessage: {
      get () {
        return this.getFieldError('agb')
      },
      set (val) {
        if (!val) return
        const locationError = this.getLocationError('agb')

        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    currentLocale () {
      return this.$store.state.locale
    }
  },
  // TODO: uncomment created
  created () {
    this.showSpinner()

    Promise.all([
      this.loadStep(),
      this.fetchPrivacyPolicy()
    ])
      .finally(() => this.hideSpinner())

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  methods: {
    loadStep () {
      return signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.UPDATE_AGREEMENT})
        .then((response) => {
          this.email = response.data.email
        })
        .catch((error) => {
          this.$router.push({ path: '/' })
        })
    },
    fetchPrivacyPolicy () {
      return signinFlowApi
        .fetchPrivacyPolicy()
        .then((response) => this.privacyPolicy = response.data.object.item.localizedPrivacyPolicy)
    },
    submitUpdateAgreement () {
      const params = {
        agb: this.agb,
        privacy: this.privacy,
      }

      this.showSpinner()
      signinFlowApi.updateAgreement(params)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    declineUpdateAgreement () {
      this.$router.push("/")
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el, index, array) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    localizeAgreementUrl(path) {
      let currentUrl = new URL(window.location)

      if (currentUrl.searchParams.get('displayType') === 'embed') {
        path = '/embed' + path
      }
      return UrlManager.localizeUrl(this.solutionHost, path)
    },
    localizeUrl (host, path) {
      return UrlManager.localizeUrl(host, path)
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/components/registration';
.ms-otp-form-title {
  text-align: start;
  @include mq('tablet', min) {
  }
  @include mq('desktop', min) {
    margin: 0 0 14px;
  }
}
.ms-otp-caption {
  @include mq('desktop', min) {
    margin: 0 0 30px;
  }
}
.ms-otp-register-agree-text::v-deep {
  max-height: 320px;
  padding: 29px 20px 36px 36px;
  margin: 0 auto 33px;
  font-family: $origin-fonts-medium;
  b {
    font-size: 18px;
    font-family: $origin-fonts-medium;
    font-weight: 500;
  }
}
</style>
