<template>
  <div class="ms-otp-registration">
    <spinner :loading="!!loaderCount" :position="'absolute'"/>

    <div class="ms-otp-form-title">{{ $t('auth.patientRegistration.page.title') }}</div>
    <div class="ms-otp-caption">{{ $t('auth.patientRegistration.page.description') }}</div>

    <form @submit.prevent="submitRegistrationForm" id="registrationStepForm">
      <div class="ms-otp-form-content-wrapper">
        <div class="ms-otp-form-content-title">1. {{ $t('auth.patientRegistration.step1') }}</div>
        <div v-bind:class="[firstNameFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input class="ms-otp-input" type="text" name="firstName" ref="firstNameInput"
                 :placeholder="$t('auth.patientRegistration.placeholder.firstName')"
                 v-model="firstName" @focus="firstNameFieldErrorMessage = null"/>
          <span v-if="firstNameFieldErrorMessage" class="ms-otp-error-text">{{ firstNameFieldErrorMessage }}</span>
        </div>
        <div v-bind:class="[lastNameFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input class="ms-otp-input" type="text" name="lastName"
                 :placeholder="$t('auth.patientRegistration.placeholder.lastName')"
                 v-model="lastName" @focus="lastNameFieldErrorMessage = null">
          <span v-if="lastNameFieldErrorMessage" class="ms-otp-error-text">{{ lastNameFieldErrorMessage }}</span>
        </div>
        <div v-bind:class="[emailFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input class="ms-otp-input" type="email" name="email"
                 :placeholder="$t('auth.patientRegistration.placeholder.email')"
                 v-model="email" @focus="emailFieldErrorMessage = null" disabled>
          <span v-if="emailFieldErrorMessage" class="ms-otp-error-text">{{ emailFieldErrorMessage }}</span>
        </div>
        <span class="ms-otp-checkbox-wrap ms-no-margin">
            <input type="checkbox" class="ms-otp-checkbox" id="show-password" v-model="checked"/>
            <label for="show-password" class="ms-otp-check-label">
              {{ $t('auth.patientRegistration.setPassword.title') }}
            </label>
            <span @click="toggleShowInformation"
                  :class="{ active: showInformation }"
                  class="ms-otp-tooltip-pw">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50" fill="#32B1AB">
                <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                </svg>
                <span class="ms-tooltip-text">{{ $t('auth.patientRegistration.setPassword.tooltip') }}</span>
            </span>
          </span>
        <div v-if="checked" class="ms-set-password">
          <div v-bind:class="[passwordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input v-if="showPassword" class="ms-otp-input" type="text" name="password"
                     :placeholder="$t('auth.patientRegistration.placeholder.password')"
                     v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
              <input v-else type="password" class="ms-otp-input" :placeholder="$t('auth.login.password.placeholder')"
                     v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
              <div :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }" class="ms-show-password-btn"
                   @click="togglePasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span v-if="passwordFieldErrorMessage" class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
          </div>
          <div v-bind:class="[confirmPasswordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input v-if="showConfirmPassword" class="ms-otp-input" type="text" name="password"
                     :placeholder="$t('auth.patientRegistration.placeholder.confirmPassword')"
                     v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null" autocomplete="new-password">
              <input v-else type="password" class="ms-otp-input" :placeholder="$t('auth.login.confirmPassword.placeholder')"
                     v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null" autocomplete="new-password">
              <div :class="{ 'ms-eye': showConfirmPassword, 'ms-eye-slash': !showConfirmPassword }" class="ms-show-password-btn"
                   @click="toggleConfirmPasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span v-if="confirmPasswordFieldErrorMessage" class="ms-otp-error-text">{{ confirmPasswordFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-form-content-title ms-second-title">2. {{ $t('auth.patientRegistration.step2') }}</div>
        <span v-bind:class="[privacyFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
            <input type="checkbox" class="ms-otp-checkbox" id="privacy" name="privacy" v-model="privacy"
                   @change="privacyFieldErrorMessage = privacy">
            <label class="ms-otp-check-label" for="privacy"
                   v-html="$t('auth.patientRegistration.dataPrivacy.label', [localizeAgreementUrl('/content/legal')])"></label>
          </span>
          <div class="ms-otp-register-agree-text" v-html="privacyPolicy"></div>
          <span v-bind:class="[agbFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
            <input type="checkbox" class="ms-otp-checkbox" id="agb" name="agb" v-model="agb"
                   @change="agbFieldErrorMessage = agb">
            <label class="ms-otp-check-label" for="agb"
                   v-html="$t('auth.patientRegistration.agb.label', [localizeAgreementUrl('/content/userAgreement')])"></label>
        </span>
        <div class="ms-otp-btn-wrap">
          <router-link :to="{ path: localizeUrl('', '/') }" class="ms-otp-btn ms-otp-btn-outline">
            {{ $t('default.back.title') }}
          </router-link>
          <input type="submit" class="ms-otp-btn" :value="$t('default.register.title')"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IconEyeSlashed from '@/components/icons/IconEyeSlashed.vue'
import IconEye from '@/components/icons/IconEye.vue'
import UrlManager from '@/util/UrlManager'
import {LoginOption} from "@/constants/LoginOption";
import {mixinLoader} from "@/mixins/mixin-loader";
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";

export default {
  name: 'Registration',
  mixins: [mixinLoader],
  components: {
    IconEye,
    IconEyeSlashed
  },
  metaInfo () {
    return {
      title: `${this.$t('auth.patientRegistration.label.title')} - Medicosearch`
    }
  },
  data () {
    return {
      errors: [],
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      privacy: false,
      agb: false,
      checked: false,
      showPassword: false,
      showConfirmPassword: false,
      showInformation: false,
      privacyPolicy: ''
    }
  },
  watch: {
    currentLocale (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showSpinner()
        this.fetchPrivacyPolicy()
          .then(() => this.hideSpinner())
      }
    }
  },
  computed: {
    solutionHost () {
      return process.env.VUE_APP_SOLUTION_HOST
    },
    signinProcessHeaders () {
      const headers = {}
      if (this.$route.query?.signinProcessToken) {
        headers[SigninProcessHeader.SIGNIN_PROCESS_TOKEN] = this.$route.query.signinProcessToken
      }
      return headers
    },
    firstNameFieldErrorMessage: {
      get () {
        return this.getFieldError('firstName')
      },
      set () {
        const locationError = this.getLocationError('firstName')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    lastNameFieldErrorMessage: {
      get () {
        return this.getFieldError('lastName')
      },
      set () {
        const locationError = this.getLocationError('lastName')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    emailFieldErrorMessage: {
      get () {
        return this.getFieldError('email')
      },
      set () {
        const locationError = this.getLocationError('email')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    passwordFieldErrorMessage: {
      get () {
        return this.getFieldError('password')
      },
      set () {
        const locationError = this.getLocationError('password')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    confirmPasswordFieldErrorMessage: {
      get () {
        return this.getFieldError('confirmPassword')
      },
      set () {
        const locationError = this.getLocationError('confirmPassword')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    privacyFieldErrorMessage: {
      get () {
        return this.getFieldError('privacy')
      },
      set(val) {
        if (!val) return
        const locationError = this.getLocationError('privacy')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    agbFieldErrorMessage: {
      get () {
        return this.getFieldError('agb')
      },
      set(val) {
        if (!val) return
        const locationError = this.getLocationError('agb')

        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    currentLocale () {
      return this.$store.state.locale
    }
  },
  // TODO: uncomment created
  created () {
    this.showSpinner()
    Promise.all([
      this.loadStep(),
      this.fetchPrivacyPolicy()
    ])
      .finally(() => this.hideSpinner())

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  mounted() {
    if (this.$refs.firstNameInput) {
      this.$refs.firstNameInput.focus();
    }
  },
  methods: {
    loadStep () {
      const params = {
        signinProcessStep: SigninProcessStep.REGISTRATION
      }
      return signinFlowApi.loadStep(params, this.signinProcessHeaders)
        .then((response) => {
          this.email = response.data.email
          this.firstName = response.data.firstName
          this.lastName = response.data.lastName
        })
        .catch((error) => {
          this.$router.push({ path: '/' })
        })
    },
    submitRegistrationForm () {
      const loginOption = this.checked ? LoginOption.PASSWORD : LoginOption.OTP
      const password = loginOption === LoginOption.PASSWORD ? this.password : null
      const confirmPassword = loginOption === LoginOption.PASSWORD ? this.confirmPassword : null
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        password: password,
        confirmPassword: confirmPassword,
        privacy: this.privacy,
        agb: this.agb,
        loginOption: loginOption,
      }

      this.showSpinner()
      signinFlowApi.completeRegistration(params, this.signinProcessHeaders)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    fetchPrivacyPolicy () {
      return signinFlowApi
        .fetchPrivacyPolicy()
        .then((response) => this.privacyPolicy = response.data.object.item.localizedPrivacyPolicy)
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el, index, array) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    localizeAgreementUrl(path) {
      let currentUrl = new URL(window.location)

      if (currentUrl.searchParams.get('displayType') === 'embed') {
        path = '/embed' + path
      }
      return UrlManager.localizeUrl(this.solutionHost, path)
    },
    localizeUrl (host, path) {
      return UrlManager.localizeUrl(host, path)
    },
    togglePasswordShow () {
      this.showPassword = !this.showPassword
    },
    toggleConfirmPasswordShow () {
      this.showConfirmPassword = !this.showConfirmPassword
    },
    toggleShowInformation () {
      this.showInformation = !this.showInformation
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';
@import '../../../assets/stylesheets/components/registration';

.ms-otp-form-title {
  @include mq('desktop', min) {
    margin: 0 0 6px;
  }
}
.ms-otp-caption {
  @include mq('desktop', min) {
    margin: 0 0 18px;
  }
}
.ms-otp-register-agree-text::v-deep {
  max-height: 155px;
  padding: 17px 20px 36px 13px;
  margin: 0 0 26px;
}
.ms-set-password {
  padding: 29px 0 0;
}
.ms-otp-btn-wrap {
  padding: 0;
}
</style>
