<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner :loading="!!loaderCount" :position="'absolute'"/>
      <div class="ms-otp-registration-title-wrapper">
        <div class="ms-otp-form-title">{{ $t('auth.updatePassword.title') }}</div>
        <div class="ms-otp-caption">{{ $t('auth.updatePassword.description') }}</div>
      </div>
      <form @submit.prevent="submitUpdatePassword" id="updatePasswordStepForm">
        <div class="ms-otp-form-content-wrapper">
          <div v-bind:class="[passwordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input v-if="showPassword" class="ms-otp-input" type="text" name="password"
                     :placeholder="$t('auth.patientRegistration.placeholder.password')"
                     v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
              <input v-else type="password" class="ms-otp-input" :placeholder="$t('auth.login.password.placeholder')"
                     v-model="password" @focus="passwordFieldErrorMessage = null" autocomplete="new-password">
              <div :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }" class="ms-show-password-btn"
                   @click="togglePasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span v-if="passwordFieldErrorMessage" class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
          </div>
          <div v-bind:class="[confirmPasswordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input v-if="showConfirmPassword" class="ms-otp-input" type="text" name="password"
                     :placeholder="$t('auth.patientRegistration.placeholder.confirmPassword')"
                     v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null"
                     autocomplete="new-password">
              <input v-else type="password" class="ms-otp-input"
                     :placeholder="$t('auth.login.confirmPassword.placeholder')"
                     v-model="confirmPassword" @focus="confirmPasswordFieldErrorMessage = null"
                     autocomplete="new-password">
              <div :class="{ 'ms-eye': showConfirmPassword, 'ms-eye-slash': !showConfirmPassword }"
                   class="ms-show-password-btn"
                   @click="toggleConfirmPasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span v-if="confirmPasswordFieldErrorMessage"
                  class="ms-otp-error-text">{{ confirmPasswordFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-btn-wrap">
          <router-link :to="{ path: localizeUrl('', '/') }" class="ms-otp-btn ms-otp-btn-outline">{{
              $t('default.back.title')
            }}
          </router-link>
          <input type="submit" class="ms-otp-btn" :value="$t('auth.updatePassword.button')"/>
        </div>
    </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp" alt="login image">
    </div>
  </div>
</template>

<script>
import IconEyeSlashed from '@/components/icons/IconEyeSlashed.vue'
import IconEye from '@/components/icons/IconEye.vue'
import UrlManager from '@/util/UrlManager'
import {mixinLoader} from "@/mixins/mixin-loader";
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";

export default {
  name: 'UpdatePassword',
  mixins: [mixinLoader],
  components: {
    IconEye,
    IconEyeSlashed
  },
  data () {
    return {
      errors: [],
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  computed: {
    solutionHost() {
      return process.env.VUE_APP_SOLUTION_HOST
    },
    emailFieldErrorMessage: {
      get() {
        return this.getFieldError('email')
      },
      set() {
        const locationError = this.getLocationError('email')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    passwordFieldErrorMessage: {
      get() {
        return this.getFieldError('password')
      },
      set() {
        const locationError = this.getLocationError('password')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    confirmPasswordFieldErrorMessage: {
      get() {
        return this.getFieldError('confirmPassword')
      },
      set() {
        const locationError = this.getLocationError('confirmPassword')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    currentLocale () {
      return this.$store.state.locale
    }
  },
  // TODO: uncomment created
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.UPDATE_PASSWORD})
      .then((response) => {
        this.email = response.data.email
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.UPDATE_AGREEMENT,
        SigninProcessStep.LOGIN,
      ]
    )
  },
  methods: {
    submitUpdatePassword () {
      const params = {
        password: this.password,
        confirmPassword: this.confirmPassword,
      }

      this.showSpinner()
      signinFlowApi.updatePassword(params)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.hideSpinner()
        })
    },
    getLocationError(field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError(field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el, index, array) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    localizeUrl(host, path) {
      return UrlManager.localizeUrl(host, path)
    },
    togglePasswordShow() {
      this.showPassword = !this.showPassword
    },
    toggleConfirmPasswordShow() {
      this.showConfirmPassword = !this.showConfirmPassword
    },
    showSpinner() {
      this.$emit('loading-increment')
    },
    hideSpinner() {
      this.$emit('loading-decrement')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-form-content-wrapper {
  @include flexbox;
  @include flex-direction(column);
}

.ms-otp-form-row {
  margin-bottom: 16px;
  &:last-child {
    margin: 0;
  }
}

.ms-otp-form-content-title {
  font-family: $origin-fonts-bold;
  font-size: 14px;
  color: $dark-green-color;
  margin-bottom: 21px;
}

.ms-otp-checkbox-wrap {
  position: relative;
}

.ms-otp-tooltip-pw {
  position: absolute;
  right: -30px;

  &.active {
    .ms-tooltip-text {
      display: block;
      opacity: 1;
      visibility: visible;
      @include mq('tablet', min) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include mq('tablet', min) {
    &:hover {
      .ms-tooltip-text {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .ms-tooltip-text {
    position: absolute;
    top: 30px;
    left: unset;
    right: -44px;
    display: none;
    width: 300px;
    background-color: #FFFFFF;
    padding: 4px 6px;
    font-family: $origin-fonts-regular;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    @include box-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
    @include border-radius(6px);
    @include transition(.3s);
    z-index: 2;
    @include mq('desktop', min) {
      left: -6px;
    }

    &:before {
      content: " ";
      position: absolute;
      top: -12px;
      left: unset;
      right: 48px;
      border-top: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #FFFFFF;
      @include mq('desktop', min) {
        left: 10px;
      }
    }
  }
}
</style>
