<template>
  <header :class="[customHeaderClass ? customHeaderClass : '', 'ms-header-wrap']">
    <div class="ms-container">
      <a :href="localizeUrl('/')" class="ms-header-logo">
        <header-logo-image />
      </a>
      <div class="ms-header-controls">
        <locale-selector/>
        <a
          v-bind:href="localizeUrl('/onboarding')"
          aria-label="$t('header.auth.actions.register_practice')"
          class="ms-practice-sign"
        >
          <icon-medcase />
          {{ $t("header.auth.actions.register_practice") }}
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import localeSelector from './LocaleSelector'
import UrlManager from '@/util/UrlManager'
import IconMedcase from '@/components/icons/IconMedcase'
import HeaderLogoImage from '@/components/images/header/HeaderLogoImage'

export default {
  components: {
    HeaderLogoImage,
    IconMedcase,
    'locale-selector': localeSelector,
  },
  methods: {
    localizeUrl(path) {
      return UrlManager.localizeUrl(process.env.VUE_APP_SOLUTION_HOST, path)
    }
  },
  computed: {
    customHeaderClass () {
      return this.$route.meta.customHeaderClass
    },
  },
  name: 'NoSearchHeader'
}
</script>

<style lang="scss">
.ms-header-wrap {
  @include flexbox;
  @include align-items(center);
  height: 76px;
  background: rgb(197,246,219);
  background: linear-gradient(90deg, rgba(197,246,219,1) 0%, rgba(163,231,208,1) 100%);
  @include transition(.3s);

  @include mq('tablet', min) {
    height: 102px;
    padding-bottom: 9px;
  }
  @include mq('desktop', min) {
    height: 114px;
    padding-bottom: 0;
  }
  @include mq('desktop-wide', min) {
  }
  @include mq('desktop-large', min) {
    height: 124px;
  }

  &.sso-header {
    .ms-language-selector {
      @include mq('tablet', min) {
        margin: 0 35px 0 0;
      }
      @include mq('desktop', min) {
        margin: 0;
      }
    }
    .ms-practice-sign,
    .ms-header-logo svg {
      display: none;
    }
  }
  &.sso-header-smn {
    .ms-language-selector {
      @include mq('tablet', min) {
        margin: 0 35px 0 0;
      }
      @include mq('desktop', min) {
        margin: 0;
      }
    }
    .ms-practice-sign {
      display: none;
    }
  }
  .ms-container {
    @include flexbox;
    @include justify-content(space-between);
  }
}

.ms-header-logo {
  margin-right: auto;
  z-index: 2;

  @include mq('desktop', min) {
    margin-right: 0;
  }
  svg {
    width: 150px;
    height: 28px;
    @include mq('tablet', min) {
      width: 234px;
      height: 42px;
    }
    @include mq('desktop-fullhd', min) {
      width: 334px;
      height: 60px;
    }
  }
}
.ms-header-controls {
  @include flexbox;
  @include align-items(center);
}
.ms-practice-sign {
  @include flexbox;
  @include align-items(center);
  position: relative;
  width: 100%;
  height: 42px;
  background-color: $origin-color-bg;
  border: 1px solid $origin-color-text;
  @include border-radius(9px);
  font-family: $origin-fonts-medium;
  font-size: 15px;
  line-height: 16px;
  color: $white-text;
  text-decoration: none;

  @include mq('tablet', max) {
    display: none;
  }
  @include mq('tablet', min) {
    width: auto;
    padding: 0 20px 0 56px;
    @include border-radius(12px);
    text-align: start;
    @include transition(0.15s);
    &:hover {
      background-color: transparent;
      color: $origin-color-text;
    }
  }

  svg {
    width: 27px;
    height: 26px;
    @include mq('tablet', max) {
      margin-right: 8px;
    }
    @include mq('tablet', min) {
      position: absolute;
      top: 50%;
      left: 16px;
      @include transform(translate(0%, -50%));
    }
  }
}
</style>
