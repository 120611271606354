<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner :loading="!!loaderCount" :position="'absolute'"/>
      <auth-device-image class="ms-auth-device-image" />
      <div class="ms-otp-form-title">{{ $t('auth.twoFactorAuthDevice.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.twoFactorAuthDevice.description') }}</div>
      <input type="button" class="ms-otp-btn ms-otp-btn-lg" @click="pushToBackStep" :value="$t('default.back.title')"/>
      <div class="ms-link-navigation ms-link-navigation-custom">
        <a class="ms-forward-link" href="javascript:" @click='pushTo2FACodeStep' v-html="$t('auth.twoFactorAuthDevice.sendSmsInsteadText')" />
      </div>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp" alt="login image">
    </div>
  </div>
</template>

<script>
import UrlManager from '@/util/UrlManager'
import {signinFlowApi} from "@/api/SigninFlowApi";
import {mixinLoader} from "@/mixins/mixin-loader";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import Stomp from "webstomp-client";
import {toastNotification} from "@/mixins/toast-notification";
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";
import AuthDeviceImage from '@/components/images/AuthDeviceImage'

export default {
  name: 'Auth2FADevice',
  components: { AuthDeviceImage },
  mixins: [
    mixinLoader,
    toastNotification,
  ],
  data () {
    return {
      errors: [],
      approvalRequestId: null,
      stompClient: null,
      reconnectAttempts: 0,
      reconnectTimeout: 5000,
      maxReconnectAttempts: 5,
      websocketConnected: false,
      websocketSecurityError: false,
    }
  },
  // TODO: uncomment created
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.AUTH_2FA_DEVICE})
      .then((response) => {
        this.send2FADevice()
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.UPDATE_AGREEMENT,
        SigninProcessStep.UPDATE_PASSWORD,
        SigninProcessStep.LOGIN,
      ]
    )
  },
  computed: {
    currentLocale () {
      return this.$store.state.locale
    },
  },
  methods: {
    send2FADevice () {
      this.showSpinner()
      signinFlowApi.send2FADevice()
        .then((response) => {
          this.approvalRequestId = response.data.approvalRequestId
          this.connectToWebsocket()
        })
        .catch((error) => {
          this.showWebsocketConnectionError()
        })
        .finally(() => {
          this.hideSpinner()
        })
    },
    connectToWebsocket () {
      this.stompClient = Stomp.client(process.env.VUE_APP_AUTH_WEB_SOCKET_URL)
      this.stompClient.connect(
        {
          signinProcessStep: SigninProcessStep.AUTH_2FA_DEVICE
        },
        (frame) => {
          this.websocketConnected = true
          this.reconnectAttempts = 0
          this.stompClient.subscribe(
            '/topic/2fa/device/' + this.approvalRequestId,
            (message) => {
              const data = JSON.parse(message.body)
              this.stompClient.disconnect()
              this.enforceNextStep(data)
            }
          )
        },
        (error) => {
          this.stompClient.disconnect()
          this.websocketConnected = false

          if (error?.command === 'ERROR' && error?.headers?.message?.includes('SubscribeToWebsocketException')) {
            this.websocketSecurityError = true
          }

          this.reconnectAttempts++
          if (!this.websocketSecurityError && this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(this.connectToWebsocket, this.reconnectTimeout)
          } else {
            this.showWebsocketConnectionError()
          }
        },
      )
    },
    showWebsocketConnectionError () {
      this.showErrorNotification(this.$t('auth.twoFactorAuthDevice.connectionError'))
    },
    enforceNextStep (data) {
      const params = {
        currentStep: SigninProcessStep.AUTH_2FA_DEVICE,
      }
      const headers = {
        [SigninProcessHeader.SIGNIN_PROCESS_TOKEN]: data.signinProcessToken,
      }
      signinFlowApi.enforceStep(params, headers)
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.$router.push({ path: '/' })
        })
    },
    pushToBackStep() {
      this.showSpinner()
      this.$router.push({ path: '/' })
    },
    pushTo2FACodeStep() {
      this.showSpinner()
      this.stompClient.disconnect()
      this.$router.push({ path: '/signin/2fa/code' })
    },
    localizeUrl (path) {
      return UrlManager.localizeUrl('', path)
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
  beforeDestroy () {
    if (this.stompClient && this.websocketConnected) {
      this.stompClient.disconnect()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';
.ms-otp-content {
  max-width: unset!important;
  @include align-items(flex-start);

  @include mq('tablet', min) {
    padding: 96px 0 76px;
  }
  @include mq('desktop', min) {
    padding: 96px 0 76px;
  }
  .ms-auth-device-image {

    @include mq('tablet', max) {
      width: 48px;
      height: 32px;
    }
    margin: 0 0 12px;
  }
  .ms-otp-form-title {
    max-width: unset;
    margin-top: 0!important;
  }
  .ms-otp-caption {
    max-width: 488px;
  }
}
</style>
