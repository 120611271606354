<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner :loading="!!loaderCount" :position="'absolute'"/>
      <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthSuccess.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthSuccess.description') }}</div>
      <form @submit.prevent="enable2FASuccess" id="enable2FASuccessForm" autocomplete="off">
        <div class="ms-otp-btn-wrap">
          <input type="submit" class="ms-otp-btn" id="submit" :value="$t('default.next.title')">
        </div>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp" alt="login image">
    </div>
  </div>
</template>

<script>
import {signinFlowApi} from "@/api/SigninFlowApi";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {SigninProcessService} from "@/service/SigninProcessService";
import {mixinLoader} from "@/mixins/mixin-loader";

export default {
  name: 'Enable2FASuccess',
  mixins: [mixinLoader],
  // TODO: uncomment created
  created () {
    this.showSpinner()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.ENABLE_2FA_SUCCESS})
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.LOGIN,
      ]
    )
  },
  computed: {},
  methods: {
    enable2FASuccess () {
      this.showSpinner()
      signinFlowApi.enable2FASuccess()
        .then((response) => {
          this.signinProcessService.pushToNextSigninStep(response.data)
        })
        .catch((error) => {
          this.hideSpinner()
        })
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-form-title {
  @include mq('desktop', min) {
    font-size: 36px;
  }
}
</style>
